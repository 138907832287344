
.image-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-template-rows: 10px;
    gap: 0 10px;
}

.image-list img {
    width: 100%;
}
